/* eslint-disable max-len */

"use client";

import "./globals.css";
import { Inter } from "next/font/google";
import { ThemeProvider, CssBaseline } from "@mui/material";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Provider as JotaiProvider } from "jotai";
import { Toaster } from "react-hot-toast";
import { Provider, ErrorBoundary } from "@rollbar/react"; // Provider imports 'rollbar'
import { rollbarConfig } from "../utils/rollbar";
import { theme } from "../utils/themes";
import AuthWrapper from "../components/AuthWrapper";
import "react-awesome-lightbox/build/style.css";

const inter = Inter({ subsets: ["latin"] });

export default function RootLayout({ children }) {
  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <html lang="en">
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <body className={`${inter.className} min-h-screen`}>
              <Container
                maxWidth="sm"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "100vh",
                  flexDirection: "column",
                  background: "url('/bg_bubble.png')",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <JotaiProvider>
                  <AuthWrapper>
                    {children}
                  </AuthWrapper>
                  <Typography
                    sx={{
                      fontSize: 10,
                      mt: 3,
                    }}
                  >
                    <b>Attention:</b> Rest assured, your personal information and documents are stored with the highest level of security and confidentiality. We use advanced encryption and secure servers to protect your data, ensuring your privacy and peace of mind. Thank you for trusting us with your information.
                  </Typography>
                </JotaiProvider>
              </Container>
              <Toaster />
            </body>
          </ThemeProvider>
        </html>
      </ErrorBoundary>
    </Provider>
  );
}
