"use client";

import { useEffect, useState } from "react";
import { useRouter } from "next/navigation";
import { onAuthStateChanged } from "firebase/auth";
import Loader from "../Common/Loader";
import { auth, getCurrentUser } from "../../utils/firebaseProvider";
import { getUserKycStatus } from "../../apiHelpers/getUserKycStatus";
import { logger } from "../../utils/rollbar";

export default function AuthWrapper({ children }) {
  const [isUserLoggedIn, setUserLoggedIn] = useState(false);
  const router = useRouter();

  useEffect(() => {
    onAuthStateChanged(auth, async () => {
      // Firebase takes sometime to load, so we need to wait for it to load before we can check if the user is logged in or not
      setUserLoggedIn(true);

      try {
        const res = await getUserKycStatus();
        const userStatus = res?.data?.status;

        const currentUser = await getCurrentUser();

        if (currentUser) { // if current user session is available then route to appropriately
          if (!userStatus || userStatus === "SUBMISSION_PENDING") { // If there is no KYC status found that means user has logged in for first time
            router.push("/Accommodation"); // Take user to start uploading KYC details
          } else if (["SUBMITTED", "APPROVED", "REJECTED", "BLOCKED"].indexOf(userStatus) > -1) {
            router.push("/kycStatus");
          }
        } else {
          router.push("/"); // Route to Welcome page
        }
      } catch (err) {
        logger.error(err);
      }
    });
  }, [router]);

  return (
    <>
      {isUserLoggedIn ? children : <Loader/>}
    </>
  );
}
